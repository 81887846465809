*, *::before {
    box-sizing: border-box;
}


.globo {
    position: fixed;
    font-size: 12px;
    width: 340px;
    height: 188px;
    bottom: 100px;
    right: 10px;
    padding: var(--borde);
    --borde: 1em;
    font-family: sans-serif;
    z-index: 10000;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px 16px 0px 16px;
    border: 1px solid #FFD60E;
    background: #FFF;
    filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.69));

}


.globo::before {
    content: "";
    position: absolute;
    z-index: -1;
    width: var(--colita);
    height: var(--colita);
    border-bottom: 1px solid #FFD60E;
    border-left: 1px solid transparent;
    border-right: 1px solid #FFD60E;
    transform: skewX(45deg);
}

.globo.abajo-derecha::before {
    /*--colita: 20px;*/
    /*left: 80%;*/
    /*bottom: calc(var(--colita) / -1);*/
    /*border-radius: 0 0 0 50px;*/
    /*transform: rotateZ(0deg);*/
    /*border-bottom: 1px solid #FFD60E;*/
    /*border-left: 1px solid #FFD60E;*/
    /*border-right: 1px solid #FFD60E;*/
    /*box-shadow: inset calc(var(--colita) / 2) calc(var(--colita) / 3) 0 8px white;*/
    --colita: -12px;
    width: 24px;
    height: 23px;
    left: 80%;
    bottom: var(--colita);
    background-color: white;
    transform: skewY(45deg);

}
