.modal-contenedor {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
  background-color: #FFFFFF;
  border-radius: 16px;
  border: none;
}

:focus-visible {
  outline: none!important;
}

.modal-loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.close-button{
  opacity: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: flex-end;
}

div.titulo {
  border-radius: 20px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 16px 0px;
}

p.titulo {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  display: flex;
  align-items: center;
  color: #212121;
}

.mensaje {
  border-radius: 20px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 16px 0px;
}

.descripcion {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #212121;  
}
